<template>
  <div class="page">
    <h2 class="main-title">№ {{getTitle}}</h2>
    <v-row>
      <v-col cols="12" md="8">
        <SubMenu/>
      </v-col>
      <v-col cols="12" md="4" v-if="!isEdit">
        <v-row
        justify="end"
        v-if="
        $store.state.login.cekkmp && $store.state.smp.statusTwo
        || $store.state.login.gbs && $store.state.smp.statusTwo
        || $store.state.login.administrator && $store.state.smp.statusTwo
        || $store.state.login.administrator && $store.state.smp.statusFourteen
        || $store.state.login.cekkmp && $store.state.smp.statusThirteen
        || $store.state.login.gbs && $store.state.smp.statusThirteen
        || $store.state.login.administrator && $store.state.smp.statusThirteen
        || $store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment && $store.state.smp.statusThirteen
        || $store.state.login.employee && $store.state.smp.statusFourteen
        ">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="white--text"
              @click.stop="dialog = true"
              v-if="$store.state.login.employee && $store.state.smp.statusFourteen"
              v-bind="attrs"
              v-on="on"
            >
              Принять
            </v-btn>
            <v-btn
              color="primary"
              class="white--text"
              @click.stop="dialog = true"
              v-bind="attrs"
              v-on="on"
              v-if="$store.state.login.cekkmp && $store.state.smp.statusTwo"
            >
              Уведомить Минздрав
            </v-btn>
          </template>
          <span>{{$store.state.login.employee && $store.state.smp.statusFourteen ? 'Принять уведомление о возможном непредоставлении в срок проекта СМП' : 'Уведомить Минздрав о возможном непредоставлении в срок проекта СМП'}}</span>
        </v-tooltip>

          <v-dialog
            v-model="dialog"
            max-width="520"
          >
            <v-card>
              <v-card-title class="text-h6">
                {{$store.state.login.employee && $store.state.smp.statusFourteen ? 'Перед принятием уведомления о возможном непредоставлении проекта СМП в срок рекомендуется загрузить документ с подтверждением в подраздел "Документы" текущей карточки СМП. Вы уверены что хотите принять уведомление?'
                : 'Перед уведомлением Минздрава о возможном непредоставлении проекта СМП в срок рекомендуется загрузить документ с обоснованием в подраздел "Документы" текущей карточки СМП. Вы уверены что хотите отправить уведомление?'}}
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <router-link
                  :to="{name: 'documents'}">
                  <v-btn
                    color="green darken-1"
                    text
                  >
                    Перейти в документы
                  </v-btn>
                </router-link>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
                >
                  Отменить
                </v-btn>

                <v-btn
                  color="green darken-1"
                  text
                  @click="statusUpdate({
                    standartId: $route.params.id,
                    statusId: $store.state.login.employee && $store.state.smp.statusFourteen ? '9333206B-70FB-46FF-9D9F-E9A8DC97698E' : '92B0ED1A-FBD2-4FAD-B542-09BBA8D8F976',
                    stageId: '0DD880C6-ACAA-4F97-9AA2-1E0AD441A5C9'
                  })"
                >
                  Отправить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" v-else>
      <v-row justify="end">
          <v-btn
            color="white"
            class="red--text ma-2"
            @click="openRemark()"
            v-if="
            $store.state.login.cekkmp
            || $store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment
            || $store.state.login.administrator"
          >
          <v-icon
            left
            dark
          >
            mdi-chat-processing-outline
          </v-icon>
            Добавить примечание
          </v-btn>
      </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" md="2">
        <SideBar/>
      </v-col>
      <v-col cols="10" md="10">
        <router-view/>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogNote"
      max-width="558"
     >
      <v-card>

        <v-card-title class="text-h6">
          <v-row>
            <div class="col-12 d-flex justify-space-between">
              <span>Добавить примечание</span>
              <v-icon
                color="darken-2"
                @click="dialogNote = !dialogNote"
              >
                mdi-close
              </v-icon>
            </div>
            <div class="col-12">
              <v-autocomplete
                 v-if="searchNote"
                :items="getNotes"
                v-model="query.name"
                label="Поиск по примечаниям"
              ></v-autocomplete>
            </div>
          </v-row>
        </v-card-title>

        <v-textarea
          name="textarea"
          class="textarea"
          outlined
          v-model="query.name"
          label="Введите примечание"
        ></v-textarea>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            text
            @click="searchNote = !searchNote"
          >
            Выбрать примечание
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="addNewNotes()"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import SubMenu from '@/components/SubMenu/SubMenu.vue'
  import SideBar from '@/components/SideBar/SideBar.vue'
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Pasport',
    components: {
      SideBar,
      SubMenu
    },
    data () {
      return {
        dialog: false,
        dialogNote: false,
        searchNote: false,
        query: {
          standartId: '',
          name: '',
          typeCode: 2,
          section: '',
          linkName: "",
          columnName: '',
          filterName: "",
          filterPage: '',
          filterFreq: "",
          filterCountSKD: "",
          filterUnit: "",
          filterSDD: "",
          filterATCCode: ""
        },
      }
    },
    computed : {
      ...mapGetters({
          getTitle: 'smp/getTitle',
          isEdit: 'smp/isEdit',
          getId: 'smp/getId',
          getNotes: 'lookups/getNotes'
      }),
    },
    methods: {
      ...mapMutations({
          setEditor: 'smp/setEditor',
          menuMutations: 'login/menuMutations'
      }),
      ...mapActions({
        standart: 'smp/standart',
        standartUpdate: 'smp/standartUpdate',
        addNote: 'smp/addNote',
        notes: 'lookups/notes',
      }),
      statusUpdate(data){
        this.standartUpdate(data).then(( )=> {
        this.standart(this.$route.params.id)
          this.dialog = false
        })
      },
      openRemark() {
        if (this.isEdit) {
          this.dialogNote = true
          this.query.standartId = this.getId
          this.query.name = ''
          this.typeCode = 2
        } else {
          return false
        }
      },
      addNewNotes() {
        this.addNote(this.query).then(() => {
          this.dialogNote = false
          this.standart(this.$route.params.id)
        })
      }
    },
    mounted() {
      this.standart(this.$route.params.id)
      this.menuMutations(this.$route.matched[0].name)
    },
    beforeDestroy() {
      if (this.isEdit) this.setEditor()
    }
  }
</script>
