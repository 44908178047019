<template>
  <div class="side-bar">
    <span v-for="link in getSideBar" :key="link.name">
      <router-link
        class="sidebar-link"
        v-if="link.access"
        active-class="active"
        exact
        :to="{name: link.to}">
        {{link.name}}
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path d="M1.89796 1.38374L5.72608 5.04288L2.06694 8.87101L3.24834 10.0003L8.03676 4.99073L3.0272 0.202311L1.89796 1.38374Z" fill="#177DC6"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="9.80048" height="9.80048" fill="white" transform="translate(0.221191 10.0686) rotate(-91.2929)"/>
            </clipPath>
          </defs>
        </svg>
      </router-link>
    </span>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    computed : {
      ...mapGetters({
          getSideBar: 'login/getSideBar',
      }),
    },
  }
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
