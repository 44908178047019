<template>
  <nav class="sub-menu">
    <span v-for="link in getSubMenu" :key="link.name">
      <router-link
        class="link"
        v-if="link.access"
        active-class="active"
        :to="link.link">
        {{link.name}}
      </router-link>
    </span>
  </nav>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  computed : {
    ...mapGetters({
        getSubMenu: 'login/getSubMenu',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
